import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './application-footer.css';

const ApplicationFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="application-footer">
      <div>&copy; Copyright NWEA {currentYear}</div>
      <div>INTERNAL USE ONLY</div>
    </footer>
  );
};

ApplicationFooter.defaultProps = {};

ApplicationFooter.propTypes = {
  customClassName: PropTypes.string,
};

export default memo(ApplicationFooter);
