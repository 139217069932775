/*
 *  This file is intended to hold all constants that are used throughout the component,
 *      as well as all non-lifecycle functions.
 *  Think of this as a 'utils' file that is specific to the component.
 */

// Reference objects for attribute values
export const EXAMPLE_FORMAT_VALUES = {
  FOO: 'foo',
  BAR: 'bar',
  BLAH: 'blah',
};

export const EXAMPLE_SIZE_VALUES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

// Typescript 'types' based upon values in reference objects
export type ExampleFormatValues = typeof EXAMPLE_FORMAT_VALUES;
export type ExampleFormatValue = ExampleFormatValues[keyof ExampleFormatValues];

export type ExampleSizeValues = typeof EXAMPLE_SIZE_VALUES;
export type ExampleSizeValue = ExampleSizeValues[keyof ExampleSizeValues];

export type Options = {
  EXAMPLE_FORMAT_VALUES: ExampleFormatValues;
  EXAMPLE_SIZE_VALUES: ExampleSizeValues;
};

export type Attribute = 'example-format' | 'example-size';

export type AttributeValue = ExampleFormatValue | ExampleSizeValue | string;

// Default values
export const DEFAULT_EXAMPLE_FORMAT_VALUE: ExampleFormatValue =
  EXAMPLE_FORMAT_VALUES.FOO;
export const DEFAULT_EXAMPLE_SIZE_VALUE: ExampleFormatValue =
  EXAMPLE_SIZE_VALUES.MEDIUM;

// List of attributes that the component will recognize
export const ATTRIBUTES: readonly Attribute[] = [
  'example-format',
  'example-size',
];

// Helper functions (aka 'utils')
export const fillChildElement = (
  childElement: HTMLSpanElement,
  prefix: string | null,
  suffix: string | null
) => {
  // Trivial example of a helper function
  let joiner;
  switch (suffix?.toLowerCase()) {
    case EXAMPLE_SIZE_VALUES.SMALL:
      joiner = '_';
      break;
    case EXAMPLE_SIZE_VALUES.MEDIUM:
      joiner = '-';
      break;
    default:
      joiner = '';
  }
  const content =
    prefix === null || suffix === null ? '' : `${prefix}${joiner}${suffix}`;
  childElement.innerText =
    suffix === EXAMPLE_SIZE_VALUES.LARGE ? content.toUpperCase() : content;
};
