import React, { Component } from 'react';

import './error-boundary.css';

export default class ErrorBoundary extends Component {
  
  componentDidCatch(err, errInfo) {
    // TODO Implement a method to return error information to cloudwatch logs
    console.error('Uncaught error:', err, errInfo);
  }

  render() {
    return <div className='error-boundary'>Oops! Something went wrong, but the issue has been logged.</div>;
  }
}