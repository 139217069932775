/*
  EXAMPLE: <nwea-ds-v1-hello-world color="blue" size="medium">Hello World</nwea-ds-v1-hello-world>

  HTML Attributes:
    color: from Options.COLORS (defaults to 'Gray' if not specified)
    size: from Options.SIZES

  JS Properties:
    color (get and set), size (get and set), hello (get)
 */
import NweaDsBaseElement from '../baseElement/baseElement';
import {
  COLORS,
  SIZES,
  Attribute,
  AttributeValue,
  ATTRIBUTES,
  Options,
  setClasses,
  DEFAULT_COLOR,
  DEFAULT_SIZE,
  Color,
  Size,
} from './helloWorld.resources';

import styles from './helloWorld.css';
import typographyStyles from '../typography/typography.css';
import { getAttributeAsEnumValue } from '../utils/type-utils';
import { getShadowRoot } from '../utils/utils';
import { version } from './stories/helloWorld.dsm-props.json';

export class NweaDsV1HelloWorld extends NweaDsBaseElement {
  static get elementName(): string {
    return 'nwea-ds-v1-hello-world';
  }

  static get observedAttributes(): readonly Attribute[] {
    return ATTRIBUTES;
  }

  static get OPTIONS(): Options {
    return { COLORS, SIZES };
  }

  static get dsmVersion(): string {
    return version;
  }

  constructor() {
    super();

    this.content.classList.add(
      typographyStyles[DEFAULT_SIZE],
      styles['content']
    );
    getShadowRoot(this).appendChild(this.content);
  }

  connectedCallback(): void {
    setClasses(this, this.getAttribute('color'), this.getAttribute('size'));
  }

  attributeChangedCallback(
    name: Attribute,
    oldValue: AttributeValue | null,
    newValue: AttributeValue | null
  ): void {
    switch (name) {
      case 'color':
        setClasses(this, newValue, this.getAttribute('size'));
        break;
      case 'size':
        setClasses(this, this.getAttribute('color'), newValue);
        break;
    }
  }

  // Getters and Setters for properties
  get color(): Color {
    const value: Color | null = getAttributeAsEnumValue(this, COLORS, 'color');
    return value || DEFAULT_COLOR;
  }

  set color(value: Color) {
    this.setAttribute('color', value);
  }

  get size(): Size {
    const value: Size | null = getAttributeAsEnumValue(this, SIZES, 'size');
    return value || SIZES.DEFAULT;
  }

  set size(value: Size) {
    this.setAttribute('size', value);
  }
}

export default NweaDsV1HelloWorld;
