import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './standard-layout.css';

const StandardLayout = (props) => {
  const WorkingHeader = props.workingHeader;

  return (
    <div className='standard-layout-content'>
      <WorkingHeader />
      <div className='standard-layout'>{props.children}</div>
    </div>
  );
};

StandardLayout.defaultProps = {
  workingHeader: () => {
    return null;
  }
};

StandardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  workingHeader: PropTypes.any,
};

export default memo(StandardLayout);
