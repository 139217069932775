import React from "react";
import StandardLayout from '../templates/standard-layout';
import PageHeader from '../common/page-header';
import { useParams } from 'react-router-dom';
import { AuthenticationServiceContext } from "../app-core/authentication-service";

// "user profile" page doesn't really do anything and can easily be removed. It just makes some manual testing easier

export const LAST_DETAIL_PIPELINE_ID_STORAGE_KEY = 'LAST_DETAIL_PIPELINE_ID_STORAGE_KEY';

const Profile = () => {
  const { pipelineName } = useParams();
  localStorage.setItem(LAST_DETAIL_PIPELINE_ID_STORAGE_KEY, encodeURIComponent(pipelineName));

  const ProfileHeader = () => {
    return (
      <PageHeader>
        <h1>User profile</h1>
      </PageHeader>
    )
  }

  const UserProfile = () => {
    return (
      <AuthenticationServiceContext.Consumer>
        {context => (
          context.isAuthenticated ?
          (
            <div><h2>Hello, {context.userName}!</h2></div>
          )
          :
          (<div><h2>Not logged in</h2></div>)
        )}
      </AuthenticationServiceContext.Consumer>);
  }

  return (
    <StandardLayout workingHeader={ProfileHeader}>
      <UserProfile/>
    </StandardLayout>
  );
}

export default Profile;
