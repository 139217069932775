import { ATTRIBUTES as FORM_ATTRIBUTES } from '../formElement/formElement.resources';
import { ValueOf } from '../utils/type-utils';
import { setOrRemoveAttribute } from '../utils/utils';
import { NweaDsV1SingleSelect } from './singleSelect';

export const COLORS = {
  BLUE: 'blue',
  PURPLE: 'purple',
  TEAL: 'teal',
} as const;
export type Colors = typeof COLORS;
export type Color = ValueOf<Colors>;
export const DEFAULT_COLOR = COLORS.BLUE;
export const ATTRIBUTES = [
  ...FORM_ATTRIBUTES,
  'assistive-text',
  'color',
  'disabled',
  'ds-aria-label',
  'ds-aria-labeledby',
  'error-text',
  'label',
] as const;

export type Attribute = typeof ATTRIBUTES[number];
export type Options = {
  COLORS: typeof COLORS;
};

export const SELECT_LABEL_ID = '#single-select-label';

export const setDsAriaProperties = (
  element: NweaDsV1SingleSelect,
  ariaLabel: string | null,
  ariaLabelledBy: string | null
): void => {
  const labelledByValue =
    ariaLabelledBy || (ariaLabel === null ? SELECT_LABEL_ID : null);
  setOrRemoveAttribute(
    element.nativeSelectElement,
    'aria-labelledby',
    labelledByValue
  );
  setOrRemoveAttribute(element.nativeSelectElement, 'aria-label', ariaLabel);

  const ariaHidden = ariaLabel || labelledByValue ? 'true' : null;
  setOrRemoveAttribute(element.content, 'aria-hidden', ariaHidden);
};

export const setValue = (
  element: NweaDsV1SingleSelect,
  internals: ElementInternals,
  newValue: string | null
): void => {
  internals.setFormValue(newValue);
};
export const setLabelText = (
  element: NweaDsV1SingleSelect,
  newValue: string | null
): void => {
  element.labelElement.innerText = newValue || '';
};

export const setDisabled = (
  element: NweaDsV1SingleSelect,
  newValue: string | boolean | null
): void => {
  const nonBlankValue = newValue === '' ? 'true' : newValue; // When a boolean attribute is set, it has a blank value.
  const booleanValue = newValue === 'false' ? false : Boolean(nonBlankValue);
  setOrRemoveAttribute(
    element.nativeSelectElement,
    'disabled',
    booleanValue ? '' : null
  );
};

export const setHelpText = (
  helpTextElement: HTMLElement,
  assistiveText?: string | null,
  errorText?: string | null
): void => {
  helpTextElement.innerText = errorText || assistiveText || '';
};
