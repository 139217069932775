import React from "react";

export default class MockAuthenticationService extends React.Component {
  constructor(configuration) {
    super(configuration);
    if (!configuration) {
      throw new Error('Mock authentication service must be configured');
    }
    this.configuration = configuration;

    this.isMockAuthenticated = this.configuration.isAuthenticated;

    this.username = this.configuration.username;

    this.signInStateUpdate = () => {};
    this.signOutStateUpdate = () => { };
  }

  selectAccount() {
    this.username = this.configuration.userName
    this.signIn();
  }

  signIn() {
    console.log("Mock sign-in called");
    this.isMockAuthenticated = true;
    this.signInStateUpdate();
    return Promise.resolve();
 }

  signOut() {
    console.log("Mock sign-out called");
    this.isMockAuthenticated = false;
    this.signOutStateUpdate();
    return Promise.resolve();
  }

  getTokenRedirect(request) {
    return Promise.resolve("Dummy access token");
  }

  showWelcomeMessage() {
    console.log("logged in user: " + this.username);
  }

  isAuthenticated() {
    return this.isMockAuthenticated;
  }

  setIsAuthenticated(newValue) {
    this.isMockAuthenticated = newValue;
  }

  getUsername() {
    return this.username;
  }

}
