import color from './color/color.css';
import typography from './typography/typography.css';
import elevation from './elevation/elevation.css';
import NweaDsV1HelloWorld from './helloWorld/helloWorld';
import NweaDsV1Template from './template/template';
import NweaDsV1Button from './button/button';
import NweaDsV1Checkbox from './checkbox/checkbox';
import NweaDsV1Toggle from './toggle/toggle';
import NweaDsV1SingleSelect from './singleSelect.experimental/singleSelect';

export interface Config {
  cssFile?: string;
  includeDataTestId?: boolean;
  isExperimentalEnabled?: boolean;
}

const cssFile = '/design-system.css';
export const defaultConfig = { cssFile };

// Include your component in the 'components' object so that 'registerAll' can see it,
//      as well as for unit testing (./tests/baseElement.test.ts)
export const components = {
  NweaDsV1HelloWorld,
  NweaDsV1Button,
  NweaDsV1Checkbox,
  NweaDsV1Toggle,
};

export const experimentalComponents = {
  NweaDsV1SingleSelect,
  NweaDsV1Template,
};

// // Export your component individually, here. (helps with tree-shaking)
export default components;

export function registerAll(config: Config = defaultConfig): Config {
  const extendedConfig = { ...defaultConfig, ...config };
  Object.values(components).forEach((component) => {
    component.register(extendedConfig);
  });
  if (extendedConfig.isExperimentalEnabled) {
    Object.values(experimentalComponents).forEach((component) => {
      component.register(extendedConfig);
    });
  }
  return extendedConfig;
}

export const styles = {
  color,
  typography,
  elevation,
};
