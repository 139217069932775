import {
  default as typographyStyles,
  Styles as TypographyStyles,
} from '../typography/typography.css';
import { isKeyOf, isValueOf } from '../utils/type-utils';
import { default as styles, Styles } from './helloWorld.css';
import NweaDsV1HelloWorld from './helloWorld';

export type Color = keyof Styles;
export type Colors = {
  [key: string]: Color;
};
export const COLORS: Colors = {
  GRAY: 'gray',
  BLUE: 'blue',
  GREEN: 'green',
  RED: 'red',
  ORANGE: 'orange',
  PURPLE: 'purple',
  TEAL: 'teal',
  YELLOW: 'yellow',
  LIME: 'lime',
  PINK: 'pink',
} as const;

export const DEFAULT_COLOR: Color = COLORS.GRAY;

type TypographyKey = keyof TypographyStyles;
type TypographyValue = TypographyStyles[TypographyKey];

// ideally, these would be directly tied back to typographyStyles, but
//    there is currently no way to map the literal 'default' to the literal
//    'body-copy-default' in typescript.
export const SIZES = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  LARGE: 'large',
};
export type Sizes = typeof SIZES;
export type Size = Sizes[keyof Sizes];

export const DEFAULT_SIZE: TypographyKey = 'body-copy-default';

export type Options = {
  COLORS: Colors;
  SIZES: Sizes;
};

export type AttributeValue = Color | Size;

export type Attribute = 'color' | 'size';
export const ATTRIBUTES: readonly Attribute[] = ['color', 'size'];

export const setClasses = (
  element: NweaDsV1HelloWorld,
  colorName?: string | null,
  sizeName?: string | null
): void => {
  // Validate that the supplied attributes are valid options, otherwise set them to their default values.
  const color: string =
    colorName && isKeyOf(styles, colorName)
      ? styles[colorName]
      : styles[DEFAULT_COLOR];
  const size: Size =
    sizeName && isValueOf(SIZES, sizeName) ? sizeName : SIZES.DEFAULT;
  const typographyName = `body-copy-${size}`;
  const sizeClass: TypographyValue = isKeyOf(typographyStyles, typographyName)
    ? typographyStyles[typographyName]
    : typographyStyles[DEFAULT_SIZE];
  element.content.setAttribute(
    'class',
    `${styles.content} ${sizeClass} ${color}`
  );
};
