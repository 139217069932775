import React from 'react';

import StandardLayout from '../templates/standard-layout';
import PageHeader from '../common/page-header';
import RepositoryTile from './repository-tile';

import './dashboard.css';
import { Outlet, useLoaderData } from 'react-router-dom';
import { AuthenticationServiceContext } from '../app-core/authentication-service';

const DashboardHeader = () => {
  return (
    <PageHeader>
      <h1>
        <span className="material-icons header-icon">fastfood</span>
        Dashboard
      </h1>
    </PageHeader>
  );
};

const renderTiles = (repositories, latestResultsPromises) => {
  if (!repositories) return <h3>No pipelines available</h3>;
  return repositories.map((repo) => {
    const resultsPromises = latestResultsPromises.filter(
      (promiseObj) => promiseObj.repositoryName === repo.repositoryName
    );
    return (
      <RepositoryTile
        repository={repo}
        key={repo.repositoryName}
        latestResultsPromises={resultsPromises}
      />
    );
  });
};

const Dashboard = () => {
  const { repositories, latestResultsPromises } = useLoaderData();
  return (
    <AuthenticationServiceContext.Consumer>
      {(context) => (
        <StandardLayout workingHeader={DashboardHeader}>
          <div className="dashboard">
            {context.isAuthenticated ? (
              renderTiles(repositories, latestResultsPromises)
            ) : (
              <h2>You must log in to view pipelines</h2>
            )}
          </div>
          <Outlet />
        </StandardLayout>
      )}
    </AuthenticationServiceContext.Consumer>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
