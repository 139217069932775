import { ElementWithValue } from '../formElement/formElement.resources';
import { isKeyOf, isValueOf } from './type-utils';

// This function takes svgText (which is the result of importing an SVG) and
//     transforms it to an SVGElement.  The classnames param is optional.  If
//     provided, they will be added to the SVGElement as the class property.
//     attributesToRemove is a list of attributes to strip off SVGElement
//     (it is defaulted).
//
// svgText looks like this:
//    var visibility_24px = ("<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\"><path d=\"M0 0h24v24H0V0z\" fill=\"none\"/><path d=\"M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z\"/></svg>");
//
// To use this in a component:
//
// import myGreatSVG from './great.svg';
// import {createSVGElementFromSVGText} from '../utils/utils';
//
// ...
//      this.icon = createSVGElementFromSVGTest(myGreatSVG, 'my-css-class');
//
export const createSVGElementFromSVGText = (
  svgText: string,
  classNames: string[] = [],
  attributesToRemove: string[] = ['height', 'width']
): SVGElement => {
  const span: HTMLSpanElement = document.createElement('span');
  const svg: SVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  span.appendChild(svg);
  svg.outerHTML = svgText;
  const extractedSvg: Element | null = span.firstElementChild;
  if (extractedSvg === null) {
    throw new Error('unexpected null for span.firstElementChild');
  }
  if (!(extractedSvg instanceof SVGElement)) {
    throw new Error(
      'unexpected instance for extractedSvg: ' + extractedSvg.constructor.name
    );
  }

  attributesToRemove.forEach((attribute) => {
    extractedSvg.removeAttribute(attribute);
  });
  classNames.forEach((className) => {
    extractedSvg.classList.add(className);
  });

  return extractedSvg;
};

// The following function is a convenience function to help make the setters in components more readable.
// The logic for the setting or removing of an attribute is consistent in almost every case.
// This convenience function reduces the line count significantly in a component's definition.
export const setOrRemoveAttribute = (
  element: HTMLElement,
  attributeName: string,
  value: string | null
): void => {
  if (value !== null) {
    element.setAttribute(attributeName, value);
  } else {
    element.removeAttribute(attributeName);
  }
};

// The following function is for the purpose of aiding in casting a node from HTMLElement,
//     to one that supports a 'value' attribute.
// This function does the simple work of determining if an element is one that has a 'value' property/attribute.
export const isElementWithValue = (
  node: Element | null
): node is ElementWithValue => {
  return (
    node instanceof HTMLInputElement ||
    node instanceof HTMLSelectElement ||
    node instanceof HTMLTextAreaElement
  );
};

// The following function is a convenience function for simplifying code and unit tests
//    when working with attribute values.
export const valueIsSet = (value: string | null): boolean =>
  ![null, ''].includes(value);

// The 'updateClassList' function is a convenience function for updating the classes that exist on a given element.
// NOTE: No class name should exist in both of the arrays.
//       This function does NOT guarantee an order of operation (remove => add, or vice versa).
//       Future changes to this function could alter how classes are updated.
export const updateClassList = <T extends Element>(
  element: T,
  classesToAdd: string[],
  classesToRemove: string[]
): void => {
  // Need to ensure that there are no blank class names (they lead to errors)
  const classesToAddFiltered = classesToAdd.filter((c) => c !== '');
  const classesToRemoveFiltered = classesToRemove.filter((c) => c !== '');

  if (classesToRemoveFiltered.length > 0) {
    element.classList.remove(...classesToRemoveFiltered);
  }
  if (classesToAddFiltered.length > 0) {
    element.classList.add(...classesToAddFiltered);
  }
};

// The 'updateClassesForAttributeChange' function is a convenience function for a common task that is needed when an attribute changes.
// This function can greatly help simplify code and unit tests for the 'attributeChangedCallback' lifecycle function of components.
export const updateClassesForAttributeChange = <T extends Element>(
  element: T,
  oldValue: string | null,
  newValue: string | null,
  validValues: Record<string, string>,
  defaultValue: string,
  validClasses: Record<string, string>
): void => {
  if (newValue === oldValue) {
    return;
  }
  const validatedValue = isValueOf(validValues, newValue)
    ? newValue
    : defaultValue;
  const validatedClass = isKeyOf(validClasses, validatedValue)
    ? validClasses[validatedValue]
    : '';
  const classesToRemove = Object.values(validClasses).filter(
    (c) => c !== validatedClass
  );
  updateClassList(element, [validatedClass], classesToRemove);
};

// The 'classesForValues' function returns a "clean" list of class names that match a list of possible values.
// This function is helpful for times when CSS files contain a mixture of class names regarding their application.
// For instance, if a CSS file contains classes for both Color and Sizes,
//      we only want the color class names when calling the 'updateClassesForAttributeChange' function.
// By passing in an object that represents the valid CSS color names, the other classes can be stripped out.
/* EXAMPLE:
    import { classesForValues } from '../utils/utils';
    import { COLORS } from './component.resources';
    import styles from './componentColors.css';

    // COLORS = {
    //    BLUE: 'blue',
    //    RED: 'red,
    // }

    // styles = {
    //    'blue',
    //    'red,
    //    'large',
    //    'small',
    // }

    const colorStyles = classesForValues(COLORS, styles);

    // colorStyles = {
    //    'blue',
    //    'red,
    // }
 */
export const classesForValues = (
  values: Record<string, string>,
  classes: Record<string, string>
): Record<string, string> => {
  const matchedClasses: Record<string, string> = {};
  Object.keys(classes).forEach((key: string): void => {
    if (isValueOf(values, key)) {
      matchedClasses[key] = classes[key];
    }
  });
  return matchedClasses;
};

// The 'getShadowRoot' function is a convenience function for a common task that is needed when a component needs
//      to interact with its shadowRoot.
// This function can greatly help simplify code and unit tests since the conditional test is handled here.
export const getShadowRoot = <T extends HTMLElement>(
  component: T
): ShadowRoot => {
  const _shadow: ShadowRoot | null = component.shadowRoot;
  if (_shadow === null) {
    throw new Error('Unexpected condition: shadowRoot was null');
  }
  return _shadow;
};

// The following function conducts a shallow extraction of the text found within the elements of a <slot>.
// It is useful for providing default text for things like 'aria-label'.
export const getSlottedText = (slot: HTMLSlotElement): string => {
  const childElements = slot.assignedElements(); // Get an array of HTML elements that exist in the <slot>
  // ... and aggregate their "innerText" values (if any).
  let contentText = childElements
    .map((element) => element.textContent)
    .join(' ')
    .replace(/\s{2,}/g, ' ')
    .trim();
  if (contentText === '') {
    // If no text was found, there might be text in the <slot> that isn't contained within an element.
    const childNodes = slot.assignedNodes(); // ... so look through the nodes (including text nodes) for any content.
    contentText = childNodes
      .map((element) => element.textContent)
      .join(' ')
      .replace(/\s{2,}/g, ' ')
      .trim();
  }
  return contentText;
};
