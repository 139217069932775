import NweaDsFormElement from '../formElement/formElement';

import {
  OBSERVED_ATTRIBUTES,
  ObservedAttributeType,
  ObservedAttributeValue,
  COLORS,
  DEFAULT_COLOR,
  SIZES,
  DEFAULT_SIZE,
  FILLS,
  DEFAULT_FILL,
  ALIGNMENTS,
  DEFAULT_ALIGNMENT,
  TYPES,
  DEFAULT_TYPE,
  Options,
  getIconClass,
  handleAriaLabelChange,
} from './button.resources';
import * as styles from './buttonBase.css';
import stylesColor from './buttonColor.css';
import styleSize from './buttonSizes.css';
import stylesFill from './buttonFill.css';
import stylesTooltip from './buttonTooltip.css';

import * as stylesTooltipBase from './buttonTooltipBase.css';
import { isValueOf } from '../utils/type-utils';
import {
  setOrRemoveAttribute,
  getShadowRoot,
  updateClassesForAttributeChange,
  classesForValues,
} from '../utils/utils';
import { version } from './stories/button.dsm-props.json';

const colorClasses = classesForValues(COLORS, stylesColor);
const sizeClasses = classesForValues(SIZES, styleSize);
const fillClasses = classesForValues(FILLS, stylesFill);
const tooltipAlignmentClasses = classesForValues(ALIGNMENTS, stylesTooltip);
export class NweaDsV1Button extends NweaDsFormElement {
  button: HTMLButtonElement;

  static get elementName(): string {
    return 'nwea-ds-v1-button';
  }

  static get observedAttributes(): readonly ObservedAttributeType[] {
    return OBSERVED_ATTRIBUTES;
  }

  static get OPTIONS(): Options {
    return {
      COLORS,
      SIZES,
      FILLS,
      ALIGNMENTS,
      TYPES,
    };
  }

  static get dsmVersion(): string {
    return version;
  }

  constructor() {
    super();
    const shadow = getShadowRoot(this);
    this.button = document.createElement('button');
    this.button.setAttribute('type', 'button');
    this.button.classList.add(styles.base, stylesTooltipBase['tooltip-base']);
    this.button.appendChild(this.content);
    this.button.addEventListener('click', this.handleClick.bind(this));
    shadow.appendChild(this.button);
  }

  handleClick(event: Event): void {
    // This component contains an inner button, but its click events will not
    //  trigger form submission (or resets) because it is in the shadow DOM.
    //  To make this work as expected, listen to the inner button's
    //  click event and, if the component's type is submit and it is attached
    //  to a form, submit the form.  If the type is reset and it is attached
    //  to a form, reset the form.
    if (this.type === 'submit' && this.form !== null) {
      this.form.requestSubmit();
    } else if (this.type === 'reset' && this.form !== null) {
      this.form.reset();
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    updateClassesForAttributeChange(
      this.button,
      null,
      this.color,
      COLORS,
      DEFAULT_COLOR,
      colorClasses
    );
    updateClassesForAttributeChange(
      this.button,
      null,
      this.size,
      SIZES,
      DEFAULT_SIZE,
      sizeClasses
    );
    updateClassesForAttributeChange(
      this.button,
      null,
      this.fill,
      FILLS,
      DEFAULT_FILL,
      fillClasses
    );
    updateClassesForAttributeChange(
      this.button,
      null,
      this.tooltipAlignment,
      ALIGNMENTS,
      DEFAULT_ALIGNMENT,
      tooltipAlignmentClasses
    );
    const iconClass = getIconClass(this.content);
    if (iconClass !== '') {
      this.button.classList.add(iconClass);
    }
  }

  attributeChangedCallback(
    name: ObservedAttributeType,
    oldValue: ObservedAttributeValue | null,
    newValue: ObservedAttributeValue | null
  ): void {
    super.attributeChangedCallback(name, oldValue, newValue);
    switch (name) {
      case 'ds-aria-label':
        handleAriaLabelChange(this, newValue);
        break;
      case 'focus-override': {
        const overrideRequested = newValue !== null;
        this.button.classList.toggle(
          styles['focus-override'],
          overrideRequested
        );
        break;
      }
      case 'color':
        updateClassesForAttributeChange(
          this.button,
          oldValue,
          newValue,
          COLORS,
          DEFAULT_COLOR,
          colorClasses
        );
        break;
      case 'disabled': {
        const isDisabled = newValue === '' ? true : Boolean(newValue);
        this.button.toggleAttribute('disabled', isDisabled);
        const tooltipText = this.hasAttribute('tooltip-text')
          ? this.getAttribute('tooltip-text')
          : this.getAttribute('ds-aria-label');
        // The user may or may not set a tooltip on this component.
        // If they do, a tooltip-text attribute will always be present on the button element inside the component.
        if (!tooltipText) {
          // If they do not...
          if (isDisabled) {
            // a tooltip-text will be automatically added to the button element when the component is disabled.
            this.button.setAttribute('tooltip-text', 'Disabled');
          } else {
            // If the component is re-enabled, the tooltip-text attribute will be removed from the button element.
            this.button.removeAttribute('tooltip-text');
          }
        }
        break;
      }
      case 'size':
        updateClassesForAttributeChange(
          this.button,
          oldValue,
          newValue,
          SIZES,
          DEFAULT_SIZE,
          sizeClasses
        );
        break;
      case 'fill':
        updateClassesForAttributeChange(
          this.button,
          oldValue,
          newValue,
          FILLS,
          DEFAULT_FILL,
          fillClasses
        );
        break;
      case 'title': {
        const titleError = new Error();
        titleError.name = 'Illegal Attribute Usage';
        titleError.message = `DO NOT USE TITLE ON <${NweaDsV1Button.elementName}>. Inconsistent a11y results. Use tooltip-text + tooltip-alignment instead for tooltip.`;
        throw titleError;
      }
      case 'tooltip-text':
        if (newValue) {
          // not using setOrRemoveAttribute because an empty string case should remove the tooltip-text attribute
          this.button.setAttribute('tooltip-text', newValue);
        } else {
          this.button.removeAttribute('tooltip-text');
        }
        break;
      case 'tooltip-alignment':
        updateClassesForAttributeChange(
          this.button,
          oldValue,
          newValue,
          ALIGNMENTS,
          DEFAULT_ALIGNMENT,
          tooltipAlignmentClasses
        );
        break;
    }
  }

  get dsAriaLabel(): string | null {
    return this.button.getAttribute('aria-label');
  }

  set dsAriaLabel(value: string | null) {
    handleAriaLabelChange(this, value);
  }

  get color(): string | null {
    const value = this.getAttribute('color');
    return isValueOf(COLORS, value) ? value : DEFAULT_COLOR;
  }
  set color(value: string | null) {
    setOrRemoveAttribute(this, 'color', value);
  }

  get focusOverride(): string | boolean | null {
    return this.hasAttribute('focus-override');
  }

  set focusOverride(value: string | boolean | null) {
    const convertedValue: string | null =
      value === false ? null : value === true ? '' : value;
    setOrRemoveAttribute(this, 'focus-override', convertedValue);
  }

  get fill(): string | null {
    const value = this.getAttribute('fill');
    return isValueOf(FILLS, value) ? value : DEFAULT_FILL;
  }

  set fill(value: string | null) {
    setOrRemoveAttribute(this, 'fill', value);
  }

  get size(): string | null {
    const value = this.getAttribute('size');
    return isValueOf(SIZES, value) ? value : DEFAULT_SIZE;
  }
  set size(value: string | null) {
    setOrRemoveAttribute(this, 'size', value);
  }

  get tooltipText(): string | null {
    return this.button.getAttribute('tooltip-text');
  }

  set tooltipText(value: string | null) {
    setOrRemoveAttribute(this.button, 'tooltip-text', value);
  }

  get tooltipAlignment(): string | null {
    const value = this.getAttribute('tooltip-alignment');
    return isValueOf(ALIGNMENTS, value) ? value : DEFAULT_ALIGNMENT;
  }
  set tooltipAlignment(value: string | null) {
    setOrRemoveAttribute(this, 'tooltip-alignment', value);
  }

  get type(): string | null {
    const value = this.getAttribute('type');
    return isValueOf(TYPES, value) ? value : DEFAULT_TYPE;
  }

  set type(value: string | null) {
    setOrRemoveAttribute(this, 'type', value);
  }
}

export default NweaDsV1Button;
