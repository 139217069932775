import NweaDsBaseElement from '../baseElement/baseElement';
import * as Polyfill from './internalsPolyfill';
import { setOrRemoveAttribute, isElementWithValue } from '../utils/utils';
import { Attribute, ATTRIBUTES } from './formElement.resources';

export class NweaDsFormElement extends NweaDsBaseElement {
  // 'NweaDsFormElement' is intended to be a base element for use by other form-associated elements
  //     (input fields, switches, buttons).
  // This class should NOT be instantiated directly.
  _internals: ElementInternals;
  _needsPolyfill: boolean;
  _originalValue: string | null;
  _value: string | null;
  _userModifiedValue: boolean;

  static get elementName(): string {
    return 'nwea-ds-form-element';
  }

  static get formAssociated(): boolean {
    return true;
  }

  static get observedAttributes(): readonly (Attribute | string)[] {
    return ATTRIBUTES;
  }

  constructor() {
    if (new.target === NweaDsFormElement) {
      throw TypeError(
        'Attempting to create an instance of NweaDsFormElement is not allowed. Use a subclass, instead.'
      );
    }
    super();

    // An internal 'value' needs to be tracked for user-entered values since they don't update an attribute.
    this._value = null;
    this._originalValue = null;
    this._userModifiedValue = false;

    this._needsPolyfill = typeof this.attachInternals !== 'function';
    let internals: ElementInternals | null = null;
    if (!this._needsPolyfill) {
      // Firefox has implemented the `attachInternals()` function as of v93.
      // However, it hasn't implemented the ElementInternals properties or functions (which makes absolutely no sense!).
      // Therefore, we need to do a second check once `attachInternals()` has been called to make sure that a critical
      //        function is in place.
      // If it isn't there, then we fallback to using the polyfill.
      // To check on the status of Firefox's implementation check:
      // MDN: https://developer.mozilla.org/en-US/docs/Web/API/ElementInternals#browser_compatibility
      // caniuse.com: https://caniuse.com/mdn-api_elementinternals_setformvalue
      // Date: 01/28/2022
      // Firefox Version: 96
      internals = this.attachInternals();
      this._needsPolyfill = typeof internals?.setFormValue !== 'function';
    }
    if (this._needsPolyfill || internals === null) {
      this._internals = Polyfill.attachInternals(this);
      this._internals.setFormValue(this.value || '');
    } else {
      this._internals = internals;
    }

    // User input values need to be propagated to the form.
    this.addEventListener('input', this.handleInputEvent);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null
  ): void {
    switch (name) {
      // Intentionally not handling 'disabled' since it is more of a UI state than it is a value that needs
      //      communicating to a containing form.
      // Since the child UI element(s) responsible for interacting with the user are different among sub-classed
      //      components, it makes more sense to handle any appropriate processing in the sub-classed component.
      case 'name':
        if (this._needsPolyfill) {
          this._internals.handleNameChange(oldValue, newValue);
        }
        break;
      case 'value':
        if (!this._userModifiedValue) {
          this._value = newValue;
          this._internals.setFormValue(newValue || '');
        }
        this._originalValue = newValue;
        break;
    }
  }

  connectedCallback(): void {
    this._originalValue = this.getAttribute('value');
    if (this._needsPolyfill) {
      this._internals.setFormValue(this.value || '');
    }
  }

  disconnectedCallback(): void {
    if (this._needsPolyfill) {
      this._internals.handleDomDisconnect();
    }
  }

  formResetCallback(): void {
    this.value = this._originalValue;
    this._userModifiedValue = false;
  }

  handleInputEvent(event: Event): void {
    // Override this function if special handling is needed when interacting with the element
    const input = <HTMLElement>event.composedPath()[0];
    /* istanbul ignore else*/
    if (isElementWithValue(input)) {
      this._value = input.value;
      this._internals.setFormValue(input.value);
      this._userModifiedValue = true;
    }
  }

  get disabled(): string | boolean | null {
    return this.hasAttribute('disabled');
  }

  set disabled(value: string | boolean | null) {
    const convertedValue = value === 'false' ? false : Boolean(value);
    setOrRemoveAttribute(this, 'disabled', convertedValue ? '' : null);
  }

  get form(): HTMLFormElement | null {
    return this._internals.form;
  }

  get name(): string | null {
    return this.getAttribute('name');
  }

  set name(value: string | null) {
    setOrRemoveAttribute(this, 'name', value);
  }

  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
    this._internals.setFormValue(value || '');
  }
}

export default NweaDsFormElement;
