import stylesIcon from './buttonIcon.css';
import {
  Attribute as FormAttribute,
  ATTRIBUTES as FormAttributes,
} from '../formElement/formElement.resources';
import NweaDsV1Button from './button';

// Colors
export const COLORS = {
  BLUE: 'blue',
  PURPLE: 'purple',
  TEAL_LIGHT: 'teal-light',
  TEAL_DARK: 'teal-dark',
  GRAY: 'gray',
  GREEN: 'green',
  ORANGE: 'orange',
  RED: 'red',
};
export type Colors = typeof COLORS;
export type Color = Colors[keyof Colors];
export const DEFAULT_COLOR: Color = COLORS.BLUE;

// Sizes
export type Sizes = typeof SIZES;
export type Size = Sizes[keyof Sizes];
export const SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};
export const DEFAULT_SIZE: Size = SIZES.MEDIUM;

// Fills
export type Fills = typeof FILLS;
export type Fill = Fills[keyof Fills];
export const FILLS = {
  FILLED: 'filled',
  OUTLINE: 'outline',
  FLAT: 'flat',
};
export const DEFAULT_FILL: Fill = FILLS.FILLED;

// Tooltips
export const ALIGNMENTS = {
  INLINE_LEFT: 'inline-left',
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  INLINE_RIGHT: 'inline-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};
export type Alignments = typeof ALIGNMENTS;
export type TooltipAlignment = Alignments[keyof Alignments];
export const DEFAULT_ALIGNMENT: TooltipAlignment = ALIGNMENTS.TOP_CENTER;

export type Types = typeof TYPES;
export type Type = Types[keyof Types];
export const TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};
export const DEFAULT_TYPE: Type = TYPES.BUTTON;

export type Options = {
  COLORS: Colors;
  SIZES: Sizes;
  FILLS: Fills;
  ALIGNMENTS: Alignments;
  TYPES: Types;
};

export type ObservedAttributeValue = Color | Size | Fill | TooltipAlignment;

export type ObservedAttributeType =
  | FormAttribute
  | 'ds-aria-label'
  | 'color'
  | 'focus-override'
  | 'disabled'
  | 'fill'
  | 'size'
  | 'title'
  | 'tooltip-text'
  | 'tooltip-alignment';

export const OBSERVED_ATTRIBUTES: readonly ObservedAttributeType[] = [
  ...FormAttributes,
  'ds-aria-label',
  'color',
  'focus-override',
  'disabled',
  'fill',
  'size',
  'title',
  'tooltip-text',
  'tooltip-alignment',
];

export const handleAriaLabelChange = (
  component: NweaDsV1Button,
  newValue: string | null
) => {
  const tooltipTextExists = component.hasAttribute('tooltip-text');
  if (newValue) {
    // not using setOrRemoveAttribute because an empty string case should remove the aria-label attribute
    component.button.setAttribute('aria-label', newValue);
    if (!tooltipTextExists) {
      // Here for backwards compatibility with v0.24.0 and before.
      component.button.setAttribute('tooltip-text', newValue);
    }
  } else {
    component.button.removeAttribute('aria-label');
    if (!tooltipTextExists && !component.hasAttribute('disabled')) {
      // Here for backwards compatibility with v0.24.0 and before.
      component.button.removeAttribute('tooltip-text'); // Only removed if button is enabled and there is no tooltip text.
    }
  }
};

export const getIconClass = (slot: HTMLSlotElement): string => {
  const nodes: Node[] = slot.assignedNodes();
  const svgIsFirst =
    nodes.length > 0 && nodes[0].nodeName.toLowerCase() == 'svg';
  const svgIsLast =
    nodes.length > 0 && nodes[nodes.length - 1].nodeName.toLowerCase() == 'svg';

  const textIsPresent = !svgIsFirst || !svgIsLast;
  let className = '';
  if (svgIsFirst && textIsPresent) {
    className = stylesIcon['icon-on-left'];
  } else if (svgIsLast && textIsPresent) {
    className = stylesIcon['icon-on-right'];
  } else if (svgIsFirst && !textIsPresent) {
    className = stylesIcon['icon-only'];
  }
  return className;
};
