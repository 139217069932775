import React, { useContext, useEffect, useState } from 'react';

import './execution.css';
import classnames from 'classnames';
import ApiClient from '../../api-client';
import { AuthenticationServiceContext } from '../../app-core/authentication-service';

const STATUS_CSS_CLASS_SUFFIXES = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  IN_PROGRESS: 'in-progress',
  WAITING: 'waiting',
  NA: 'na',
};

const STATUS_ICONS = {
  SUCCEEDED: 'check_circle',
  FAILED: 'cancel',
  IN_PROGRESS: 'refresh',
  WAITING: 'pending',
  NA: 'help',
};

function generateClassNamesWithStatus(className, status) {
  return `${className} ${className}-${STATUS_CSS_CLASS_SUFFIXES[status]}`;
}

export default function Execution({ result }) {
  const [signedUrl, setSignedUrl] = useState();
  const authContext = useContext(AuthenticationServiceContext);
  const apiClient = new ApiClient(window._ENV_.REACT_APP_DATA_API_BASE_URL);

  const status = result.currentStatus || 'N/A';
  const componentClasses = generateClassNamesWithStatus('execution', status);
  const indicatorClasses = generateClassNamesWithStatus(
    'execution-status',
    status
  );

  useEffect(() => {
    authContext
      .getApiToken()
      .then((token) =>
        apiClient.getArtifacts(token, result.pipelineName, result.executionId)
      )
      .then((data) => setSignedUrl(data?.url));
  }, []);

  return (
    <div className={componentClasses}>
      <div
        className={classnames('material-symbols-outlined', indicatorClasses)}
      >
        {STATUS_ICONS[status]}
      </div>
      <div className="overflow">{result.pipelineName}</div>
      <div className="overflow">{result.timeStamp}</div>
      <div>
        {signedUrl ? (
          <a href={signedUrl} target="_blank">
            Artifacts
          </a>
        ) : (
          'N/A'
        )}
      </div>
      <div>{result.commitNumber.substring(0, 12)}</div>
      <div>{result.commitDescription}</div>
    </div>
  );
}
