import React, { useEffect, useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Execution from './execution';
import PageSlider from '../common/page-slider';
import './detail.css';

export const LAST_DETAIL_REPOSITORY_NAME_STORAGE_KEY =
  'LAST_DETAIL_REPOSITORY_NAME_STORAGE_KEY';

const generateClickListener = (thisElement, action) => (event) => {
  if (!thisElement || !thisElement.current) {
    return;
  }
  if (!thisElement.current.contains(event.target)) {
    action();
  }
};

/**
 * This method calculates the running success rate for the given pipelineName from the results
 * subset provided.  Assumes the results are sorted latest -> earliest.
 * @param {object[]} resultsSubset
 * @param {string} pipelineName
 */
function calculateRunningSuccessRate(resultsSubset, pipelineName) {
  // In-progress executions are excluded
  const pipelineResults = resultsSubset.filter((result) => {
    return (
      result.pipelineName === pipelineName &&
      result.currentStatus !== 'IN_PROGRESS'
    );
  });
  const successes = pipelineResults.reduce((count, result) => {
    if (result.currentStatus === 'SUCCEEDED') {
      count++;
    }
    return count;
  }, 0);
  if (pipelineResults.length === 0) {
    return '0.0%';
  }
  return `${((successes / pipelineResults.length) * 100).toFixed(1)}%`;
}

function Detail() {
  const { currentResults, repositoryName } = useLoaderData();
  localStorage.setItem(
    LAST_DETAIL_REPOSITORY_NAME_STORAGE_KEY,
    encodeURIComponent(repositoryName)
  );

  const thisElement = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const listener = generateClickListener(thisElement, () => navigate('/'));
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [thisElement, navigate]);

  currentResults.sort((a, b) => {
    if (a.timeStamp > b.timeStamp) {
      return -1;
    }
    if (b.timeStamp > a.timeStamp) {
      return 1;
    }
    return 0;
  });
  for (let k = currentResults.length - 1; k >= 0; k--) {
    const subset = currentResults.slice(k, currentResults.length);
    subset[0].successRunningAverage = calculateRunningSuccessRate(
      subset,
      subset[0].pipelineName
    );
  }

  return (
    <PageSlider>
      <div className="detail" ref={thisElement}>
        <h2 className="detail-header">
          <span className="material-symbols-outlined">inventory_2</span>
          {repositoryName}
        </h2>
        <div className="detail-columns">
          <div>Status</div>
          <div>Pipeline</div>
          <div>Date & Time</div>
          <div>Build</div>
          <div>Commit</div>
          <div>Description</div>
        </div>
        {currentResults.map((result) => (
          <Execution key={result.commitNumber} result={result} />
        ))}
      </div>
    </PageSlider>
  );
}

export default Detail;
