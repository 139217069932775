/*
  EXAMPLE: <nwea-ds-v1-single-select>
    <option>Apples</option>
    <option>Oranges</option>
    <option>Grapes</option>
  </nwea-ds-v1-single-select>
 */
import { version } from './stories/singleSelect.dsm-props.json';
import * as Resources from './singleSelect.resources';
import * as styles from './singleSelect.css';
import * as Types from '../utils/type-utils';
import { default as typographyStyles } from '../typography/typography.css';
import { default as elevationStyles } from '../elevation/elevation.css';
import * as Utils from '../utils/utils';
import NweaDsFormElement from '../formElement/formElement';

export class NweaDsV1SingleSelect extends NweaDsFormElement {
  container: HTMLDivElement;
  contextElement: HTMLSpanElement;
  labelElement: HTMLLabelElement;
  nativeSelectElement: HTMLSelectElement;

  static get elementName(): string {
    return 'nwea-ds-v1-single-select';
  }

  static get dsmVersion(): string {
    return version;
  }

  static get OPTIONS(): Resources.Options {
    return {
      COLORS: Resources.COLORS,
    };
  }

  static get observedAttributes(): readonly Resources.Attribute[] {
    return Resources.ATTRIBUTES;
  }

  constructor() {
    super();
    const shadow = Utils.getShadowRoot(this);
    this.container = document.createElement('div');
    this.container.classList.add(styles.container);

    this.nativeSelectElement = document.createElement('select');
    this.nativeSelectElement.classList.add(
      styles.select,
      typographyStyles['body-copy-medium'],
      elevationStyles['level-0']
    );

    this.labelElement = document.createElement('label');
    this.labelElement.setAttribute('id', Resources.SELECT_LABEL_ID);
    this.labelElement.classList.add(styles.label, typographyStyles.subtitle);

    this.contextElement = document.createElement('span');
    this.contextElement.classList.add(
      typographyStyles.label,
      styles.assistiveText
    );

    /**
     * :scope > option is being careful not to double copy nested <option>'s:
     * <nwea-single-select>
     *  <optgroup> <--- this was already copied
     *    <option>Don't copy</option>
     *  </optgroup>
     *  <option>do copy</option>
     * <nwea-single-select>
     */
    const options = this.querySelectorAll(
      ':scope > option, optgroup'
    ) as NodeListOf<HTMLOptionElement | HTMLOptGroupElement>;
    options.forEach((option) => this.nativeSelectElement.add(option));

    this.container.appendChild(this.labelElement);
    this.container.appendChild(this.nativeSelectElement);
    this.container.appendChild(this.contextElement);
    shadow.appendChild(this.container);
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.nativeSelectElement.addEventListener('focusin', () => {
      this.labelElement.classList.add(styles.active);
      this.nativeSelectElement.classList.replace(
        elevationStyles['level-0'],
        elevationStyles['level-04']
      );
    });

    this.nativeSelectElement.addEventListener('focusout', () => {
      this.labelElement.classList.remove(styles.active);
      this.nativeSelectElement.classList.replace(
        elevationStyles['level-04'],
        elevationStyles['level-0']
      );
    });

    Resources.setDsAriaProperties(this, this.dsAriaLabel, this.dsAriaLabeledBy);

    Utils.updateClassesForAttributeChange(
      this.nativeSelectElement,
      null,
      this.color,
      Resources.COLORS,
      Resources.DEFAULT_COLOR,
      Utils.classesForValues(Resources.COLORS, styles)
    );

    this.labelElement.innerText = this.label || '';

    Resources.setDisabled(this, this.disabled);
    Resources.setHelpText(
      this.contextElement,
      this.assistiveText,
      this.errorText
    );
  }

  attributeChangedCallback(
    name: Resources.Attribute,
    oldValue: string | null,
    newValue: string | null
  ): void {
    super.attributeChangedCallback(name, oldValue, newValue);
    switch (name) {
      case 'assistive-text': {
        Resources.setHelpText(
          this.contextElement,
          newValue || '',
          this.errorText
        );
        break;
      }
      case 'color': {
        Utils.updateClassesForAttributeChange(
          this.nativeSelectElement,
          null,
          newValue,
          Resources.COLORS,
          Resources.DEFAULT_COLOR,
          Utils.classesForValues(Resources.COLORS, styles)
        );
        Utils.updateClassesForAttributeChange(
          this.labelElement,
          null,
          newValue,
          Resources.COLORS,
          Resources.DEFAULT_COLOR,
          Utils.classesForValues(Resources.COLORS, styles)
        );
        break;
      }
      case 'disabled': {
        Resources.setDisabled(this, newValue);
        this.labelElement.classList.toggle(styles.disabled, Boolean(newValue));
        break;
      }
      case 'ds-aria-label': {
        Resources.setDsAriaProperties(this, newValue, this.dsAriaLabeledBy);
        break;
      }
      case 'ds-aria-labeledby': {
        Resources.setDsAriaProperties(this, this.dsAriaLabel, newValue);
        break;
      }
      case 'error-text': {
        Resources.setHelpText(
          this.contextElement,
          this.assistiveText,
          newValue || ''
        );
        if (newValue) {
          this.container.classList.add(styles.error);
        } else {
          this.container.classList.remove(styles.error);
        }
        break;
      }
      case 'label': {
        Resources.setLabelText(this, newValue);
        break;
      }
    }
  }

  get assistiveText(): string | null {
    return this.getAttribute('assistive-text');
  }

  set assistiveText(value: string | null) {
    Utils.setOrRemoveAttribute(this, 'assistive-text', value);
  }

  get color(): Resources.Color {
    const value = this.getAttribute('color');
    return Types.isValueOf(Resources.COLORS, value)
      ? (value as Resources.Color)
      : Resources.DEFAULT_COLOR;
  }
  set color(value: Resources.Color) {
    Utils.setOrRemoveAttribute(this, 'color', value);
  }

  get dsAriaLabel(): string | null {
    return this.getAttribute('ds-aria-label');
  }

  set dsAriaLabel(value: string | null) {
    Utils.setOrRemoveAttribute(this, 'ds-aria-label', value);
  }

  get dsAriaLabeledBy(): string | null {
    return this.getAttribute('ds-aria-labeledby');
  }

  set dsAriaLabeledBy(value: string | null) {
    Utils.setOrRemoveAttribute(this, 'ds-aria-labeledby', value);
  }

  get errorText(): string | null {
    return this.getAttribute('error-text');
  }

  set errorText(value: string | null) {
    Utils.setOrRemoveAttribute(this, 'error-text', value);
  }

  get label(): string | null {
    return this.getAttribute('label');
  }

  set label(value: string | null) {
    Utils.setOrRemoveAttribute(this, 'label', value);
  }
}

export default NweaDsV1SingleSelect;
