import classNames from 'classnames';
import React, { memo } from 'react';

import './page-slider.css';

/**
 * @param {React.PropsWithChildren} props
 * @returns
 */
const PageSlider = ({ children }) => {

  const sliderClasses = classNames('page-slider', {
    'page-slider__slider--closing': true
  });

  const faderClasses = classNames('page-slider__fader', {
    'page-slider__fader--visible': true
  });

  return (
    <div className={sliderClasses} data-cy='page-slider'>
      <div className={faderClasses} />
      <div className='page-slider__content-wrapper'>
        {children}
      </div>
    </div>
  );
};

export default memo(PageSlider);