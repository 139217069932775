import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import reportWebVitals from './app-core/reportWebVitals';
import '@nwea/nwea-frontend-components-v1/dist/design-system.css';
import { registerAll } from '@nwea/nwea-frontend-components-v1';
import Application from './app-core';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);

registerAll();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);