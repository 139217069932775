import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './application-body.css';

const ApplicationBody = (props) => {
  return (
    <main className='application-body'>
      {props.children}
    </main>
  );
};

ApplicationBody.defaultProps = {};

ApplicationBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  customClassName: PropTypes.string,
};

export default memo(ApplicationBody);
