import React from 'react';

import ApplicationBody from '../common/application-body';
import ApplicationFooter from '../common/application-footer';
import ApplicationRoutes from '../routes';

import './application.css';
import AuthenticationService, { AuthenticationServiceContext } from "./authentication-service";
import MockAuthenticationService from "./mock-authentication-service";
import { BrowserCacheLocation } from '@azure/msal-browser';

export default class Application extends React.Component {
  constructor(props) {
    super(props);

    this.authenticationService = {};

    if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === 'development') {
      this.authenticationService = new MockAuthenticationService({ isAuthenticated: false, userName: "DEMO" })
    } else {
      const authenticationServiceConfiguration = {
        msalConfig: {
          auth: {
            clientId: window._ENV_.REACT_APP_AUTH_CLIENT_ID,
            authority: window._ENV_.REACT_APP_AUTH_AUTHORITY,
            redirectUri: window._ENV_.REACT_APP_AUTH_REDIRECT_URI,
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false,
          }
        },
        audience: window._ENV_.REACT_APP_AUTH_TOKEN_AUDIENCE,
        defaultScope: window._ENV_.REACT_APP_AUTH_TOKEN_DEFAULT_SCOPE
      }
      this.authenticationService = new AuthenticationService(authenticationServiceConfiguration);
    }

    this.doSignIn = () => {
      this.authenticationService.signIn();
    }

    this.authenticationService.signInStateUpdate = () => {
      this.setState(() => ({
        isAuthenticated: true,
        userName: this.authenticationService.getUsername()
      }));
    }

    this.doSignOut = () => {
      this.authenticationService.signOut();
    }

    this.authenticationService.signOutStateUpdate = () => {
      this.setState(() => ({
        isAuthenticated: false,
        userName: null
      }));
    }

    this.getApiToken = () => {
      if (this.authenticationService.isAuthenticated()) {
        return this.authenticationService.getTokenRedirect({});
      } else {
        // this shouldn't happen
        return Promise.reject("Not logged in");
      }
    }
    this.state = {
      isAuthenticated: false,
      userName: null,
      doSignIn: this.doSignIn,
      doSignOut: this.doSignOut,
      getApiToken: this.getApiToken,
    }
  }

  componentDidMount() {
    this.authenticationService.selectAccount();
    if (this.authenticationService.username) {
      this.authenticationService.signInStateUpdate();
    }
  }

  render() {
    return (
      <>
        <ApplicationBody>
          <AuthenticationServiceContext.Provider value={this.state}>
            <ApplicationRoutes/>
          </AuthenticationServiceContext.Provider>
        </ApplicationBody>
        <ApplicationFooter/>
      </>
    );

  }
}
