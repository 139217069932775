import NweaDsFormElement from '../formElement/formElement';
import {
  ATTRIBUTES,
  Attribute,
  AttributeValue,
  CHECKBOX_LABEL_ID,
  COLORS,
  DEFAULT_COLOR,
  LABEL_POSITIONS,
  DEFAULT_LABEL_POSITION,
  SWITCH_POSITIONS,
  DEFAULT_SWITCH_POSITION,
  Options,
  setAriaProperties,
  setDisabled,
  updateLabelPosition,
  updateSwitchPosition,
  updateValue,
} from './switch.resources';
import { isValueOf } from '../utils/type-utils';
import {
  setOrRemoveAttribute,
  getShadowRoot,
  updateClassesForAttributeChange,
  classesForValues,
} from '../utils/utils';
import styles from './switch.css';
import typographyStyles from '../typography/typography.css';

const colorStyles = classesForValues(COLORS, styles);

export class NweaDsSwitch extends NweaDsFormElement {
  // 'NweaDsSwitch' is intended to be a base element for use by other switch-like elements
  //     (checkboxes, radio buttons, toggles).
  // This class should NOT be instantiated directly.
  switch: HTMLInputElement;
  container: HTMLLabelElement;
  _checkedAttributeValue: boolean;

  static get elementName(): string {
    return 'nwea-ds-switch';
  }

  static get OPTIONS(): Options {
    return {
      COLORS: COLORS,
      LABEL_POSITIONS: LABEL_POSITIONS,
      SWITCH_POSITIONS: SWITCH_POSITIONS,
    };
  }

  static get observedAttributes(): readonly (Attribute | string)[] {
    return ATTRIBUTES;
  }

  constructor() {
    if (new.target === NweaDsSwitch) {
      throw TypeError(
        'Attempting to create an instance of NweaDsSwitch is not allowed. Use a subclass, instead.'
      );
    }
    super();

    // Used for reference when the element is reset to its original state (before any user interaction).
    this._checkedAttributeValue = false;

    const shadow = getShadowRoot(this);

    this.content.classList.add(styles.label);
    this.content.setAttribute('id', CHECKBOX_LABEL_ID);
    this.switch = document.createElement('input');
    this.switch.classList.add(styles.control);
    setAriaProperties(this, null, CHECKBOX_LABEL_ID);

    this.container = document.createElement('label');
    this.container.classList.add(
      typographyStyles['body-copy-default'],
      styles.container
    );
    this.container.appendChild(this.switch);
    this.container.appendChild(this.content);
    shadow.appendChild(this.container);
  }

  attributeChangedCallback(
    name: Attribute | string,
    oldValue: AttributeValue | null,
    newValue: AttributeValue | null
  ): void {
    super.attributeChangedCallback(name, oldValue, newValue);
    switch (name) {
      case 'checked': {
        this.updateCheckedState(newValue);
        break;
      }
      case 'color':
        updateClassesForAttributeChange(
          this.container,
          oldValue,
          newValue,
          COLORS,
          DEFAULT_COLOR,
          colorStyles
        );
        break;
      case 'data-test-id':
        NweaDsSwitch.addDataTestId(this.switch, `switch-${newValue}`);
        break;
      case 'disabled':
        setDisabled(this.switch, newValue);
        break;
      case 'ds-aria-label':
        setAriaProperties(this, newValue, this.dsAriaLabelledby);
        break;
      case 'ds-aria-labelledby':
        setAriaProperties(this, this.dsAriaLabel, newValue);
        break;
      case 'label-position':
        updateLabelPosition(this.container, oldValue, newValue);
        break;
      case 'switch-position':
        updateSwitchPosition(this.container, oldValue, newValue);
        break;
      case 'value': {
        updateValue(this, this._internals, newValue);
        break;
      }
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    updateClassesForAttributeChange(
      this.container,
      null,
      this.color,
      COLORS,
      DEFAULT_COLOR,
      colorStyles
    );
    updateLabelPosition(this.container, null, this.labelPosition);
    updateSwitchPosition(this.container, null, this.switchPosition);
    setAriaProperties(this, this.dsAriaLabel, this.dsAriaLabelledby);
    this._checkedAttributeValue = Boolean(this.checked);
    updateValue(this, this._internals, this.value); // Ensure that containing form has the value properly registered.
  }

  formResetCallback(): void {
    super.formResetCallback();
    this.switch.checked = this._checkedAttributeValue;
  }

  handleInputEvent(): void {
    // Overriding this function from `formElement` so that the value is only present when switch is checked.
    updateValue(this, this._internals, this.value);
  }

  // Property Getters & Setters
  get checked(): boolean | string | null {
    return this.switch.checked;
  }

  set checked(value: boolean | string | null) {
    // Setting the 'checked' state via the property is akin to the user clicking the switch.
    // Therefore, there is no need to update 'this._checkedAttributeValue'.
    // That variable is only updated when the 'checked' attribute is changed.
    this.switch.checked = Boolean(value); // Sync the switch state with the attribute value;
    updateValue(this, this._internals, this.value);
  }

  get color(): string | null {
    const value = this.getAttribute('color');
    return isValueOf(COLORS, value) ? value : DEFAULT_COLOR;
  }

  set color(value: string | null) {
    setOrRemoveAttribute(this, 'color', value);
  }

  get disabled(): boolean | string | null {
    return this.hasAttribute('disabled');
  }

  set disabled(value: boolean | string | null) {
    const convertedValue = value === false ? null : value === true ? '' : value;
    setOrRemoveAttribute(this, 'disabled', convertedValue);
  }

  get dsAriaLabel(): string | null {
    return (
      this.getAttribute('ds-aria-label') ||
      this.switch.getAttribute('aria-label')
    );
  }

  set dsAriaLabel(value: string | null) {
    setOrRemoveAttribute(this, 'ds-aria-label', value);
  }

  get dsAriaLabelledby(): string | null {
    return this.getAttribute('ds-aria-labelledby');
  }

  set dsAriaLabelledby(value: string | null) {
    setOrRemoveAttribute(this, 'ds-aria-labelledby', value);
  }

  get labelPosition(): string | null {
    const value = this.getAttribute('label-position');
    return isValueOf(LABEL_POSITIONS, value) ? value : DEFAULT_LABEL_POSITION;
  }

  set labelPosition(value: string | null) {
    setOrRemoveAttribute(this, 'label-position', value);
  }

  get switchPosition(): string | null {
    const value = this.getAttribute('switch-position');
    return isValueOf(SWITCH_POSITIONS, value) ? value : DEFAULT_SWITCH_POSITION;
  }

  set switchPosition(value: string | null) {
    setOrRemoveAttribute(this, 'switch-position', value);
  }

  get value(): string | null {
    return this.getAttribute('value');
  }

  set value(value: string | null) {
    setOrRemoveAttribute(this, 'value', value);
  }

  updateCheckedState(newValue: string | null): void {
    const isChecked = newValue !== null;
    this._checkedAttributeValue = isChecked; // Set 'original state' since the attribute is being updated.
    setOrRemoveAttribute(this.switch, 'checked', isChecked ? '' : null); // Sync the switch attribute with component.
    this.switch.checked = isChecked; // Sync the switch state with the attribute value;
    updateValue(this, this._internals, this.value);
  }
}

export default NweaDsSwitch;
