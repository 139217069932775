import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './page-header.css';
import LoginButton from "../login-button";
import LogoutButton from "../logout-button";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
  return (
    <div className={`page-header ${props.customClassName}`} data-cy='page-header-bar'>
      {props.children}
      <div align="right">
        <Link to={`/Profile`} className='material-icons'>account_circle</Link>
        <LoginButton/><LogoutButton/>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  customClassName: '',
};

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  customClassName: PropTypes.string,
};

export default memo(PageHeader);
