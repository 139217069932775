import NweaDsSwitch from './switch';
import styles from './switch.css';
import {
  Attribute as FormAttribute,
  ATTRIBUTES as FORM_ATTRIBUTES,
} from '../formElement/formElement.resources';
import { setOrRemoveAttribute, updateClassList } from '../utils/utils';

export const COLORS = {
  BLUE: 'blue',
  PURPLE: 'purple',
  TEAL: 'teal',
  ORANGE: 'orange',
};

export type Colors = typeof COLORS;
export type Color = Colors[keyof Colors];

export const DEFAULT_COLOR: Color = COLORS.BLUE;

export const LABEL_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  HIDDEN: 'hidden',
};

export type LabelPositions = typeof LABEL_POSITIONS;
export type LabelPosition = LabelPositions[keyof LabelPositions];

export const DEFAULT_LABEL_POSITION: LabelPosition = LABEL_POSITIONS.RIGHT;

export const SWITCH_POSITIONS = {
  TOP: 'top',
  CENTERED: 'centered',
};

export type SwitchPositions = typeof SWITCH_POSITIONS;
export type SwitchPosition = SwitchPositions[keyof SwitchPositions];

export const DEFAULT_SWITCH_POSITION: SwitchPosition = SWITCH_POSITIONS.TOP;

export const DEFAULT_VALUE = 'on';
export const CHECKBOX_LABEL_ID = 'nwea-ds-checkbox-label';

export type Options = {
  COLORS: Colors;
  LABEL_POSITIONS: LabelPositions;
  SWITCH_POSITIONS: SwitchPositions;
};

export type AttributeValue = Color | LabelPosition | SwitchPosition | string;

export type Attribute =
  | FormAttribute
  | 'ds-aria-label'
  | 'ds-aria-labelledby'
  | 'checked'
  | 'color'
  | 'data-test-id'
  | 'disabled'
  | 'label-position'
  | 'switch-position';
export const ATTRIBUTES: readonly Attribute[] = [
  ...FORM_ATTRIBUTES,
  'ds-aria-label',
  'ds-aria-labelledby',
  'checked',
  'color',
  'data-test-id',
  'disabled',
  'label-position',
  'switch-position',
];

export const setAriaProperties = (
  element: NweaDsSwitch,
  ariaLabel: string | null,
  ariaLabelledBy: string | null
): void => {
  // In Safari with VoiceOver, one of the key commands (VO+command+J => next item)
  //        will cause the readout of what's in the label (<slot>).
  // The result is a kind of double read out of the label.
  // Setting the aria-hidden prevents this,
  //        but then we also need to specify what the checkbox should read out now that its label is hidden.
  // If the consumer wants to specify an aria-label*, then we use that.
  // Otherwise, we default to an aria-labelledby of the ID for the content.
  // Date: 01/28/2022
  // Safari Version: 15.2
  const labelledByValue =
    ariaLabelledBy || (ariaLabel === null ? CHECKBOX_LABEL_ID : null);
  setOrRemoveAttribute(element.switch, 'aria-labelledby', labelledByValue);
  setOrRemoveAttribute(element.switch, 'aria-label', ariaLabel);
  // There appears to be a bug in Chrome that impacts how VoiceOver reads the accessibility tree when aria-label is placed on the checkbox.
  // Instead of reading just the aria-label, it reads both the aria-label AND the text contained with the <label>.
  // This does not happen with VoiceOver in Safari.
  // Therefore, we implement the following work-around to silence the label text if aria-* information is provided.
  // Date: 12/21/2021
  // Chrome Version: 96.0.4664.110
  const ariaHidden = ariaLabel || labelledByValue ? 'true' : null;
  setOrRemoveAttribute(element.content, 'aria-hidden', ariaHidden);
};

export const setDisabled = (
  switchElement: HTMLInputElement,
  newValue: string | null
): void => {
  const nonBlankValue = newValue === '' ? 'true' : newValue; // When a boolean attribute is set, it has a blank value.
  const booleanValue = newValue === 'false' ? false : Boolean(nonBlankValue);
  setOrRemoveAttribute(switchElement, 'disabled', booleanValue ? '' : null);
};

export const updateLabelPosition = (
  element: HTMLLabelElement,
  oldValue: string | null,
  newValue: string | null
): void => {
  if (newValue === oldValue) {
    return;
  }
  // NOTE: Position options are Right, Left, and Hidden.
  //       Special classes exist ONLY for Left and Hidden.
  //       The default option of Right is built into the base class.
  //       Therefore, an empty 'positionClass' indicates that the default position of Right is used.
  let positionClass = '';
  let positionsToRemove = [styles['label-on-left'], styles['label-hidden']];
  if (newValue === LABEL_POSITIONS.LEFT) {
    positionClass = styles['label-on-left'];
    positionsToRemove = [styles['label-hidden']];
  } else if (newValue === LABEL_POSITIONS.HIDDEN) {
    positionClass = styles['label-hidden'];
    positionsToRemove = [styles['label-on-left']];
  }
  updateClassList(element, [positionClass], positionsToRemove);
};

export const updateSwitchPosition = (
  element: HTMLLabelElement,
  oldValue: string | null,
  newValue: string | null
): void => {
  if (newValue === oldValue) {
    return;
  }
  const centeredClass = styles['control-is-centered'];
  let positionClass = '';
  let positionsToRemove = [centeredClass];
  if (newValue === SWITCH_POSITIONS.CENTERED) {
    positionClass = centeredClass;
    positionsToRemove = [];
  }
  updateClassList(element, [positionClass], positionsToRemove);
};

export const updateValue = (
  component: NweaDsSwitch,
  internals: ElementInternals,
  newValue: string | null
): void => {
  // There is additional processing needed to properly convey the value of a switch to a containing <form> that
  //      the parent FormElement is not intended to handle.
  // A switch only indicates a value to the form when it is checked. Otherwise, it sends no value.
  // While the switch will have a 'value' attribute indicated, this is only intended as a reference for when the
  //      switch is checked.
  // This behavior matches normal HTML checkbox/radio button functionality.

  // Technically, 'this.checked' can return a string or null, so ensuring a boolean value.
  const booleanValue = Boolean(component.checked);
  // Determine the value to send to the form, accounting for the state of the checkbox.
  const formValue = booleanValue ? newValue || DEFAULT_VALUE : null;
  internals.setFormValue(formValue); // Update the <form> with the proper value.
  // Sync the 'value' attributes between the checkbox component and its <input type="checkbox"> child element.
  setOrRemoveAttribute(component.switch, 'value', formValue);
  if (component._needsPolyfill) {
    if (booleanValue) {
      internals.handleNameChange(null, component.name);
    } else {
      internals.handleNameChange(component.name, null);
    }
  }
};
