/*
  EXAMPLE: <nwea-ds-v1-toggle color="green" label-position="left">Use Dark Theme</nwea-ds-v1-toggle>
 */
import NweaDsSwitch from '../switch/switch';
import checkmarkSvg from './checkmark.svg';
import styles from './toggle.css';
import { version } from './stories/toggle.dsm-props.json';
import { createSVGElementFromSVGText, getShadowRoot } from '../utils/utils';

export class NweaDsV1Toggle extends NweaDsSwitch {
  static get elementName(): string {
    return 'nwea-ds-v1-toggle';
  }

  static get dsmVersion(): string {
    return version;
  }

  constructor() {
    super();

    /* Prevents odd vertical spacing due to height of child elements */
    const hostStyle = document.createElement('style');
    hostStyle.innerHTML = `:host {
      display: inline-block;
      line-height: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem);
      vertical-align: middle;
      padding: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem) calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem);
    }`;
    const shadow = getShadowRoot(this);
    shadow.appendChild(hostStyle);

    this.switch.setAttribute('type', 'checkbox');
    this.switch.setAttribute('role', 'switch');
    this.switch.classList.add(styles.control);
    this.content.classList.add(styles.label);
    this.container.classList.add(styles.container);

    this.stylesheet.onload = () => {
      const checkmarkIcon = createSVGElementFromSVGText(checkmarkSvg, [
        styles.icon,
      ]);
      checkmarkIcon.setAttribute('aria-hidden', 'true');
      this.container.appendChild(checkmarkIcon);
    };
  }
}

export default NweaDsV1Toggle;
