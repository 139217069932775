import React from "react";
import { AuthenticationServiceContext } from "../../app-core/authentication-service";

const LoginButton = () => {
  return (
    <AuthenticationServiceContext.Consumer>
      {context => (
        context.isAuthenticated ?
          <div></div>
          :
          <button onClick={context.doSignIn} data-cy='login-button'>
            Log In
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
            </svg>
          </button>
      )}
    </AuthenticationServiceContext.Consumer>
  );
}

export default LoginButton;
