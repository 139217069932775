import { Config } from '../index';
import { unhideWhenStyled } from './baseElement.resources';

export let cssFile = '/design-system.css';

export class NweaDsBaseElement extends HTMLElement {
  // 'NweaDsBaseElement' is intended to be a base element for use by other design system elements.
  // This class should NOT be instantiated directly.
  content: HTMLSlotElement;
  stylesheet: HTMLLinkElement;

  static _includeDataTestId = false;

  static register(config?: Config): void {
    if (config && config.cssFile) {
      cssFile = config.cssFile;
    }

    NweaDsBaseElement._includeDataTestId = config?.includeDataTestId === true;

    // Check the 'customElements' property (of the window) to see if the element has been defined yet.
    if (window.customElements.get(this.elementName) === undefined) {
      window.customElements.define(this.elementName, this);
    } else {
      console.info(`<${this.elementName}> has already been registered.`);
    }
  }

  static addDataTestId<T extends Element>(
    element: T,
    dataTestIdValue: string
  ): void {
    // only adds this element when "includeDataTestId" is set to true when elements are registered
    if (NweaDsBaseElement._includeDataTestId) {
      element.setAttribute('data-test-id', dataTestIdValue);
    }
  }

  static get elementName(): string {
    return 'nwea-ds-base-element';
  }

  static get dsmVersion(): string {
    throw ReferenceError(
      `DSM Version information is missing. This must be provided by ${this.name}.`
    );
  }

  static get packageVersion(): string {
    if (process.env.VERSION) {
      return process.env.VERSION;
    } else {
      throw ReferenceError(
        `Package Version information is missing. Expected 'process.env.VERSION', but found ${process.env.VERSION}.`
      );
    }
  }

  static get version(): string {
    return `DSM Specification Version: ${this.dsmVersion}\nCode Package Version: ${this.packageVersion}`;
  }

  constructor() {
    if (new.target === NweaDsBaseElement) {
      throw TypeError(
        'Attempting to create an instance of NweaDsBaseElement is not allowed. Use a subclass, instead.'
      );
    }
    super();

    const shadow = this.attachShadow({ mode: 'open' });
    /* 
    For server-side rendering, hide the element, until the CSS loads.
    Prevents a flash of unstyled content, or FOUC.
    */
    const loadingStyle = document.createElement('style');
    loadingStyle.innerHTML = `:host {
      visibility: hidden;
    }
    * { transition-duration: 0s !important; }
    `;
    shadow.appendChild(loadingStyle);

    this.stylesheet = document.createElement('link');
    this.stylesheet.setAttribute('rel', 'stylesheet');
    this.stylesheet.setAttribute('href', cssFile);
    this.stylesheet.setAttribute('type', 'text/css');
    this.stylesheet.addEventListener('load', () =>
      unhideWhenStyled(loadingStyle)
    );

    shadow.appendChild(this.stylesheet);

    this.content = document.createElement('slot');
  }
}

export default NweaDsBaseElement;
