import type {} from 'css-font-loading-module';

export const unhideWhenStyled = (
  visibilityHiddenStyle: HTMLStyleElement
): void => {
  if (document.fonts.check('12px Open Sans')) {
    // when this is true, it indicates that the stylesheet has completed loading the fonts
    visibilityHiddenStyle.remove();
  } else {
    setTimeout(unhideWhenStyled, 10, visibilityHiddenStyle);
  }
};
