import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Dashboard from '../dashboard';
import Detail from '../detail';
import Profile from '../profile';
import ApiClient from '../api-client';
import { AuthenticationServiceContext } from '../app-core/authentication-service';
import ErrorBoundary from '../common/error-boundary';

export default class ApplicationRoutes extends React.Component {
  constructor(props) {
    super(props);

    this.apiClient = new ApiClient(window._ENV_.REACT_APP_DATA_API_BASE_URL); // Throws if not defined
  }

  static contextType = AuthenticationServiceContext;

  dashboardLoader = async () => {
    if (!this.context.isAuthenticated) return {};
    const token = await this.context.getApiToken();
    const repositories = await this.apiClient.getRepositories(token); // Fast call with no required params
    const latestResultsPromises = repositories
      .flatMap((repo) => {
        repo.pipelineNames = repo.pipelineNames || [];
        return repo.pipelineNames.map((pipe) => ({
          repositoryName: repo.repositoryName,
          pipelineName: pipe,
        }));
      })
      .map((pipeObj) => {
        pipeObj.promise = this.apiClient.getCurrentResults(
          token,
          [pipeObj.pipelineName],
          1
        );
        return pipeObj;
      });
    return {
      repositories,
      latestResultsPromises,
    };
  };

  detailLoader = async ({ params }) => {
    if (!this.context.isAuthenticated) return {};
    const repoName = params.repositoryName.replace('%2f', '/');
    const token = await this.context.getApiToken();
    const repository = await this.apiClient
      .getRepositories(token)
      .then((repos) => repos.find((repo) => repo.repositoryName === repoName));
    let currentResults = [];
    if (repository.pipelineNames?.length > 0) {
      currentResults = await this.apiClient.getCurrentResults(
        token,
        repository.pipelineNames
      );
    }
    return {
      currentResults,
      repositoryName: repository.repositoryName,
    };
  };

  getApplicationRoutes() {
    return [
      {
        path: '/',
        element: <Dashboard />,
        errorElement: <ErrorBoundary />,
        loader: this.dashboardLoader,
        children: [
          {
            path: '/repository/:repositoryName',
            element: <Detail />,
            loader: this.detailLoader,
          },
        ],
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ];
  }

  getRouter() {
    return createBrowserRouter(this.getApplicationRoutes());
  }

  render() {
    return <RouterProvider router={this.getRouter()} />;
  }
}
