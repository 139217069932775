import * as msal from '@azure/msal-browser';
import React from "react";

export const AuthenticationServiceContext = React.createContext({
  isAuthenticated: false,
  userName: null,
  doSignIn: () => {},
  doSignOut: () => {},
  getApiToken: () => {}
});

export default class AuthenticationService extends React.Component {
  constructor(configuration) {
    super(configuration);
    if (!configuration) {
      throw new Error('Authentication service must be configured');
    }
    this.configuration = configuration;

    this.msalClientApp = new msal.PublicClientApplication(this.configuration.msalConfig);

    this.signInStateUpdate = () => {};
    this.signOutStateUpdate = () => {};

    /**
     * A promise handler needs to be registered for handling the
     * response returned from redirect flow. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
     */
    this.msalClientApp.handleRedirectPromise()
      .then(response => this.handleResponse(response))
      .catch((error) => {
        console.error(error);
      });

    this.username = null;
  }

  selectAccount() {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = this.msalClientApp.getAllAccounts();

    if (currentAccounts.length === 0) {
      return;
    } else if (currentAccounts.length > 1) {
      // TODO: Add account choosing logic here
      //  it is unclear if we need to do this. It seems to be to support non-MS multi-account responses.
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      this.username = currentAccounts[0].username;
    }
  }

  handleResponse(response) {
    if (response?.account) {
      this.username = response.account.username;
      this.signInStateUpdate();
    } else {
      this.selectAccount();
    }
  }

  async signIn() {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    return this.msalClientApp.loginRedirect()
      .catch((e) => {
        console.log(e);
      });
  }

  async signOut() {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    const logoutRequest = {
      account: this.msalClientApp.getAccountByUsername(this.username),
      postLogoutRedirectUri: this.configuration.msalConfig.auth.redirectUri,
    };

    return this.msalClientApp.logoutRedirect(logoutRequest)
      .then(() => {
        this.username = null;
        this.signOutStateUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getTokenRedirect(request) {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account = this.msalClientApp.getAccountByUsername(this.username);
    request.audience = this.configuration.audience;
    request.scopes = [this.configuration.defaultScope]

    return this.msalClientApp.acquireTokenSilent(request)
      .then(response => {
        return response.accessToken;
      })
      .catch(error => {
        console.warn("silent token acquisition fails. acquiring token using redirect");
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return this.msalClientApp.acquireTokenRedirect(request)
            .then(response => {
              return response.accessToken;
            });
        } else {
          console.warn(error);
        }
      });
  }

  isAuthenticated() {
    return this.username !== null;
  }

  getUsername() {
    return this.username;
  }

}
