import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './repository-tile.css';

const BRANCH = 'Branch';
const pipelineSuffix = {
  CI: /[-_]ci-pipeline/,
  CICD: /[-_]cicd-pipeline/,
};

const buildStatusIcons = {
  SUCCEEDED: 'check_circle',
  FAILED: 'error',
  IN_PROGRESS: 'pending',
  WAITING: 'pause_circle',
  NA: 'help',
};

const getBuildStatusIcon = (buildStatus) => {
  return buildStatusIcons[buildStatus];
};

const getBuildIndicatorClassnames = (buildStatus) => {
  const baseClasses = 'material-icons repository-tile__indicator';
  if (buildStatus === 'SUCCEEDED') {
    return baseClasses.concat(' ', 'repository-tile__indicator-success');
  }
  if (buildStatus === 'FAILED') {
    return baseClasses.concat(' ', 'repository-tile__indicator-failure');
  }
  return baseClasses;
};

const generatePipelineLabel = (latestResult) => {
  let label = Object.keys(pipelineSuffix).find((key) =>
    pipelineSuffix[key].test(latestResult.pipelineName)
  );
  if (!label) {
    label = BRANCH;
  }
  return label;
};

const renderExecutionState = ({ currentStatus }) => {
  const indicatorClassNames = getBuildIndicatorClassnames(currentStatus);
  const icons = getBuildStatusIcon(currentStatus);
  return <span className={indicatorClassNames}>{icons}</span>;
};

const getPipelineStatus = (latestResult) => {
  const unknownFallback = (
    <span className={getBuildIndicatorClassnames('NA')}>
      {getBuildStatusIcon('NA')}
    </span>
  );
  if (!latestResult) {
    return unknownFallback;
  } else {
    return renderExecutionState(latestResult);
  }
};

const renderPipelineIndicator = (latestResult) => {
  if (!latestResult || typeof latestResult === 'string') {
    return null;
  }
  return (
    <div className="repository-tile__item" key={latestResult.pipelineName}>
      <div className="repository-tile__tooltip">
        {latestResult.pipelineName}
      </div>
      <h3 className="repository-tile__pipe-label">
        {generatePipelineLabel(latestResult)}
      </h3>
      {getPipelineStatus(latestResult)}
    </div>
  );
};

const filterOutOldBranchBuilds = (results) => {
  const branchBuilds = results.filter((result) => {
    if (result) return generatePipelineLabel(result) === BRANCH;
  });

  const mostRecentBranchBuild = branchBuilds.reduce((mostRecent, result) => {
    if (mostRecent.timestamp < result.timestamp) mostRecent = result;
    return mostRecent;
  }, branchBuilds.at(0));

  const ciBuild = results.find(
    (result) => result?.pipelineName.search(pipelineSuffix.CI) > -1
  );

  const cicdBuild = results.find(
    (result) => result?.pipelineName.search(pipelineSuffix.CICD) > -1
  );
  return [ciBuild, cicdBuild, mostRecentBranchBuild];
};

const TileContent = (props) => {
  return (
    <div className="repository-tile__content" data-cy="repository-tile-content">
      <h2>{props.repoName}</h2>
      <div className="repository-tile__pipes">
        {filterOutOldBranchBuilds(props.results).map(renderPipelineIndicator)}
      </div>
    </div>
  );
};

const RepositoryTile = (props) => {
  let ciPipeName, cicdPipeName;
  if (props.repository.pipelineNames) {
    ciPipeName = props.repository.pipelineNames.find(
      (pipeName) => pipeName.search(pipelineSuffix.CI) > -1
    );
    cicdPipeName = props.repository.pipelineNames.find(
      (pipeName) => pipeName.search(pipelineSuffix.CICD) > -1
    );
  }
  // Initialized with placeholder elements to ensure branch executions will be pushed in at the end of the
  // array and won't be overwritten
  const [latestResults, setLatestResults] = useState([undefined, undefined]);

  const handleResult = (response) => {
    const result = response[0];
    if (!result) {
      return;
    }
    if (result.pipelineName === ciPipeName) {
      setLatestResults((prevState) => [result, ...prevState.slice(1)]);
    } else if (result.pipelineName === cicdPipeName) {
      setLatestResults((prevState) => [
        prevState[0],
        result,
        ...prevState.slice(2),
      ]);
    } else {
      setLatestResults((prevState) => {
        return [...prevState, result];
      });
    }
  };

  useEffect(() => {
    let shouldUpdate = true;

    props.latestResultsPromises.map((resultPromise) => {
      return resultPromise.promise.then((result) => {
        if (result && shouldUpdate) {
          handleResult(result);
        }
      });
    });

    // Passing the cleanup function prevents double rendering of results (usually only in development)
    return () => {
      shouldUpdate = false;
    };
  }, []);

  const repoName = props.repository.repositoryName.replace('/', '%2f');
  return (
    <Link
      to={`/repository/${repoName}`}
      className="repository-tile"
      data-cy="repository-tile-button"
      aria-label={`View details for repository ${props.repository.repositoryName}`}
    >
      <TileContent
        repoName={props.repository.repositoryName}
        results={latestResults}
      />
    </Link>
  );
};

RepositoryTile.propTypes = {
  latestResultsPromises: PropTypes.any,
  repository: PropTypes.any.isRequired,
};

export default memo(RepositoryTile);
