import React from "react";
import { AuthenticationServiceContext } from "../../app-core/authentication-service";

const LogoutButton = () => {
  return (
    <AuthenticationServiceContext.Consumer>
      {context => (
        context.isAuthenticated ?
          <button onClick={context.doSignOut} data-cy='logout-button'>
            Log Out
          </button>
          :
          <div></div>
      )}
    </AuthenticationServiceContext.Consumer>
  );
};

export default LogoutButton;