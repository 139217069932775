export const isKeyOf = <T, U extends Record<string, T>>(
  object: U,
  keyOrString: string | keyof U
): keyOrString is keyof U => {
  return keyOrString in object;
};

export type ValueOf<T> = T[keyof T];
export const isValueOf = <T, U extends Record<string, T>>(
  object: T,
  valueOrNot: unknown | T
): valueOrNot is T => {
  return Object.values(object).includes(valueOrNot);
};

// calls getAttribute on component and checks to see if the value
//    returned is a property in enumObj.  If a value is returned
//    and is a property in enumObj, it returns the value.  Otherwise,
//    it returns null.
export const getAttributeAsEnumValue = <T, U extends Record<string, T>>(
  component: HTMLElement,
  enumObj: U,
  attributeName: string
): T | null => {
  const attribute: string | null = component.getAttribute(attributeName);

  if (attribute != null && isKeyOf(enumObj, attribute)) {
    return enumObj[attribute];
  }
  return null;
};
