import axios from 'axios';

const REPOSITORIES_ENDPOINT = '/repositories';
const CURRENT_RESULTS_ENDPOINT = '/current-results';
const ARTIFACTS_ENDPOINT = '/artifacts';

/**
 * Simple service wrapper around axios to allow for easy mocking of responses in offline environments
 * or when an API is otherwise unavailable.
 */
export default class ApiClient {
  #basePath;

  /**
   * Accepts a string referencing a URL base path.
   * @param {string} basePath
   */
  constructor(basePath) {
    if (!basePath) {
      throw new Error('Base path required for service');
    }
    this.#basePath = basePath;
  }

  async getRepositories(token) {
    return this.#getApiDataWithToken(
      `${this.#basePath}${REPOSITORIES_ENDPOINT}`,
      {},
      token
    ).then((response) => response?.data);
  }

  async getCurrentResults(token, pipelineNames, size) {
    if (!pipelineNames) {
      return Promise.resolve({ currentResults: [] });
    }
    const params = new URLSearchParams();
    pipelineNames.forEach((name) => params.append('pipelineName', name));
    if (size) {
      params.append('size', size);
    }
    return this.#getApiDataWithToken(
      `${this.#basePath}${CURRENT_RESULTS_ENDPOINT}`,
      { params },
      token
    ).then((response) => response?.data);
  }

  async getArtifacts(token, pipelineName, executionId) {
    const params = new URLSearchParams();
    params.append('pipelineName', pipelineName);
    params.append('executionId', executionId);
    return this.#getApiDataWithToken(
      `${this.#basePath}${ARTIFACTS_ENDPOINT}`,
      { params },
      token
    ).then((response) => response?.data);
  }

  async #getApiDataWithToken(endpoint, options, token) {
    const optionsWithToken = {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: (status) => {
        return status < 500;
      },
    };
    return axios
      .get(endpoint, optionsWithToken)
      .catch((error) => console.error(error));
  }
}
