import NweaDsFormElement from './formElement';
import { setOrRemoveAttribute, valueIsSet } from '../utils/utils';

export function attachInternals(element: NweaDsFormElement): ElementInternals {
  const _element = element;

  // When an element is connected to a form, an event listener needs to be added to the form for the 'reset' event.
  // The purpose of the event listener is to execute the 'formResetCallback' function of the form element
  //     so that the element is properly reset when the user requests it.
  // The connected form needs to be tracked in case the form element is removed and attached to a different form.
  // In the rare times that an element is moved to a different form, the reset event listener needs to be removed
  //     from the previous form.
  let _connectedForm: HTMLFormElement | null = null;

  // NOTE: Using an arrow function should make the signature of this event listener different for every form element.
  const resetAction = () => {
    _element.formResetCallback();
  };

  // _proxy acts as a proxy for the custom element that it represents.
  // _proxy is placed outside of the element's shadow DOM so that it can be seen by the containing form.
  // This polyfill is therefore responsible for keeping the hidden input field in sync with the custom element.
  const _proxy = document.createElement('input');
  _proxy.setAttribute('type', 'hidden');
  _proxy.setAttribute('name', element.name || '');
  _proxy.setAttribute('value', element.value || '');
  // The following no-value attribute is added as a "tag" for easy identification as to its purpose.
  _proxy.setAttribute('data-form-element-proxy', '');

  const internals: ElementInternals = {
    // Per Design System preferences, validity checking is to be handled by the consuming application, not the browser.
    willValidate: false,
    validity: {
      valueMissing: false,
      typeMismatch: false,
      patternMismatch: false,
      tooLong: false,
      tooShort: false,
      rangeUnderflow: false,
      rangeOverflow: false,
      stepMismatch: false,
      badInput: false,
      customError: false,
      valid: false,
    },
    validationMessage: '',
    checkValidity: (): boolean => {
      return true;
    },
    reportValidity: (): boolean => {
      return true;
    },
    // At this time, we are not implementing any handling of 'File' or 'FormData' types in the 'setFormValue' function.
    setFormValue: function (value: string | null) {
      // }, state: string | null): void {
      const elementName = _element.name;
      const containingForm = this.form;
      if (containingForm !== null && elementName !== null) {
        setOrRemoveAttribute(_proxy, 'value', value);
        // If a form element has no name, then its proxy is automatically removed from the DOM.
        //      (see 'handleNameChange()' in formElement.ts)
        // The following condition ensures that the proxy is re-added to the DOM when the name is restored.
        if (!_proxy.isConnected) {
          _element.appendChild(_proxy);
          // If the consuming app has moved the element to a different form, or the form was never registered...
          if (containingForm !== _connectedForm) {
            _connectedForm?.removeEventListener('reset', resetAction); // Make sure that no event listener exists on the previous form.
            _connectedForm = containingForm;
            _connectedForm.addEventListener('reset', resetAction); // ... and add a reset event listener to the current form.
          }
        }
        if (elementName !== _proxy.name) {
          _proxy.setAttribute('name', elementName);
        }
      }
    },

    handleDomDisconnect: function (): void {
      // When an element is removed from the DOM, it needs to have it's former containing form 'reset' event listener removed.
      // 'ignore next' comment needed for optional chaining.
      //      Can't test that 'removeEventListener' was NOT called on an object that doesn't exist.
      /* istanbul ignore next */
      _connectedForm?.removeEventListener('reset', resetAction);
      _connectedForm = null;
    },

    handleNameChange(oldName: string | null, newName: string | null) {
      // The code below ensures that the element proxy stays in sync with the web component.
      if (!valueIsSet(newName) && _proxy.isConnected) {
        // If the name is being removed (new name is null)...
        _element.removeChild(_proxy); // then remove the proxy since the element is no longer valid.
      } else if (!valueIsSet(oldName) && valueIsSet(newName)) {
        // If name is being re-established...
        _element.appendChild(_proxy); // then re-add it to the DOM.
      }
      if (newName !== oldName && newName !== null) {
        _proxy.setAttribute('name', newName); // Update the name of the proxy, too.
      }
    },

    get form(): HTMLFormElement | null {
      return _element.closest('form');
    },

    get labels(): NodeList {
      return _element.querySelectorAll('label');
    },

    get shadowRoot(): ShadowRoot | null {
      return _element.shadowRoot;
    },
  };

  if (internals.form !== null && _element.name !== null) {
    _element.appendChild(_proxy);
    _connectedForm = internals.form;
    _connectedForm.addEventListener('reset', resetAction);
  }

  return internals;
}
