/*
  EXAMPLE: <nwea-ds-v1-checkbox color="green" label-position="left">Hello World</nwea-ds-v1-checkbox>
 */
import NweaDsSwitch from '../switch/switch';
import {
  Attribute as SwitchAttribute,
  ATTRIBUTES,
  AttributeValue,
} from '../switch/switch.resources';
import {
  createSVGElementFromSVGText,
  getShadowRoot,
  setOrRemoveAttribute,
} from '../utils/utils';
import checkboxSvg from './checkbox.svg';
import indeterminateSvg from './indeterminate.svg';
import styles from './checkbox.css';
import { version } from './stories/checkbox.dsm-props.json';

type Attribute = SwitchAttribute | 'indeterminate';

export class NweaDsV1Checkbox extends NweaDsSwitch {
  _originalIndeterminateState: boolean;

  static get elementName(): string {
    return 'nwea-ds-v1-checkbox';
  }

  static get dsmVersion(): string {
    return version;
  }

  static get observedAttributes(): readonly Attribute[] {
    return [...ATTRIBUTES, 'indeterminate'];
  }

  constructor() {
    super();
    this._originalIndeterminateState = false;

    /* Prevents odd vertical spacing due to height of child elements */
    const hostStyle = document.createElement('style');
    hostStyle.innerHTML = `:host {
      display: inline-block;
      line-height: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem);
      vertical-align: middle;
      padding: calc(var(--nwea-ds-switch-rem-conversion) * 0.8rem) calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem);
    }`;
    const shadow = getShadowRoot(this);
    shadow.appendChild(hostStyle);

    this.switch.setAttribute('type', 'checkbox');
    this.switch.classList.add(styles.control);
    this.content.classList.add(styles.label);

    this.stylesheet.onload = () => {
      const checkmarkIcon = createSVGElementFromSVGText(checkboxSvg, [
        styles.icon,
        styles.checkmark,
      ]);
      checkmarkIcon.setAttribute('aria-hidden', 'true');
      this.container.appendChild(checkmarkIcon);

      const indeterminateIcon = createSVGElementFromSVGText(indeterminateSvg, [
        styles.icon,
        styles.indeterminate,
      ]);
      indeterminateIcon.setAttribute('aria-hidden', 'true');
      this.container.appendChild(indeterminateIcon);
    };
  }

  attributeChangedCallback(
    name: Attribute,
    oldValue: AttributeValue | null,
    newValue: AttributeValue | null
  ): void {
    super.attributeChangedCallback(name, oldValue, newValue);
    switch (name) {
      case 'indeterminate': {
        this.handleIndeterminateChange(newValue);
        break;
      }
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._originalIndeterminateState = Boolean(this.indeterminate);
  }

  formResetCallback(): void {
    super.formResetCallback();
    this.indeterminate = this._originalIndeterminateState;
  }

  get indeterminate(): boolean | string | null {
    return this.switch.hasAttribute('indeterminate');
  }

  set indeterminate(value: boolean | string | null) {
    const convertedValue = value === false ? null : value === true ? '' : value;
    setOrRemoveAttribute(this, 'indeterminate', convertedValue);
  }

  handleIndeterminateChange(newIndeterminateValue: string | null): void {
    const isIndeterminate = newIndeterminateValue !== null;
    this.switch.toggleAttribute('indeterminate', isIndeterminate);
    setOrRemoveAttribute(
      this.switch,
      'aria-checked',
      isIndeterminate ? 'mixed' : null
    );
  }
}

export default NweaDsV1Checkbox;
