/*
  EXAMPLE: <nwea-ds-v1-template example-format="foo" example-size="large">Hello Template</nwea-ds-v1-template>
 */

// Always extend from the 'baseElement', unless you are building a form-associated element.
import NweaDsBaseElement from '../baseElement/baseElement';

// Resources and Utilities
import {
  Attribute,
  ATTRIBUTES,
  AttributeValue,
  DEFAULT_EXAMPLE_FORMAT_VALUE,
  DEFAULT_EXAMPLE_SIZE_VALUE,
  EXAMPLE_FORMAT_VALUES,
  EXAMPLE_SIZE_VALUES,
  fillChildElement,
  Options,
} from './template.resources';
import {
  getShadowRoot,
  setOrRemoveAttribute,
  updateClassesForAttributeChange,
} from '../utils/utils';
import { isValueOf } from '../utils/type-utils';
import { version } from './stories/template.dsm-props.json';

// Stylesheets (applying via JS objects instead of string values)
import * as baseStyles from './template.css';
import formatStyles from './template-format.css';
import sizeStyles from './template-size.css';

export class NweaDsV1Template extends NweaDsBaseElement {
  // 'NweaDsV1Template' is intended as an example component for reference purpose only.
  // This class should NOT be instantiated - EVER!

  exampleChild: HTMLSpanElement; // Example of a child element that is managed by the component.

  static get elementName(): string {
    return 'nwea-ds-v1-template'; // Update this to return the correct element name of your component.
  }

  static get dsmVersion(): string {
    return version;
  }

  static get observedAttributes(): readonly Attribute[] {
    return ATTRIBUTES;
  }

  static get OPTIONS(): Options {
    // Useful for consumers to utilize accepted values and guard against mistyping.
    return {
      EXAMPLE_FORMAT_VALUES,
      EXAMPLE_SIZE_VALUES,
    };
  }

  constructor() {
    super(); // Always call this first within the constructor to execute common initialization tasks.

    const shadow = getShadowRoot(this); // Needed for any interactions with the component's shadow DOM.

    /*
     * COMPONENT-CONTAINER STYLING: Optional
     *      Only use the following style to format the container (if necessary - otherwise remove).
     *      NEVER use this to style the internal elements of the component.
     */
    const hostStyle = document.createElement('style');
    // Example styling - Not intended for all components. Adjust or remove.
    hostStyle.innerHTML = `
            :host {
              display: inline-block;
              padding: calc(var(--nwea-ds-template-rem-conversion) * 0.8rem) calc(var(--nwea-ds-template-rem-conversion) * 0.1rem);
            }`;
    shadow.appendChild(hostStyle);

    /*
     * CHILD ELEMENT SETUP: Optional
     *      Create any child elements along with any initial core styling.
     *      Finished styling comes in a different lifecycle method.
     */
    this.exampleChild = document.createElement('span');
    this.exampleChild.classList.add(baseStyles['example-child']);

    /*
     * CONTENT ELEMENT STYLING: Optional
     *      `this.content` is the `<slot>` element (created in the parent NweaDsBaseElement)
     *           that displays all children elements passed to the component.
     */
    this.content.classList.add(baseStyles['example-content']);

    /*
     * CONTENT/CHILD INCLUSION: Required (usually)
     *      Add all elements to the shadow DOM.
     *      Control styling and visibility via CSS.
     *      NOTE: There are rare situations when the `content` element isn't intended to be included
     *            (i.e. when the component doesn't accept children elements).
     *            In these cases, it is not necessary to append it to the shadow DOM.
     */
    shadow.appendChild(this.exampleChild);
    shadow.appendChild(this.content);
  }

  /*
   * LIFECYCLE FUNCTIONS: Required
   *      'attributeChangedCallback' => Responds to changes in any of the attributes that are watched
   *          (identified in the static getter 'observedAttributes')
   *      'connectedCallback' => Occurs when component is added to the app's DOM.
   */

  attributeChangedCallback(
    name: Attribute,
    oldValue: AttributeValue | null,
    newValue: AttributeValue | null
  ): void {
    switch (name) {
      case 'example-format': {
        updateClassesForAttributeChange(
          this.content,
          oldValue,
          newValue,
          EXAMPLE_FORMAT_VALUES,
          DEFAULT_EXAMPLE_FORMAT_VALUE,
          formatStyles
        );
        fillChildElement(this.exampleChild, newValue, this.exampleSize);
        break;
      }
      case 'example-size': {
        updateClassesForAttributeChange(
          this.content,
          oldValue,
          newValue,
          EXAMPLE_SIZE_VALUES,
          DEFAULT_EXAMPLE_SIZE_VALUE,
          sizeStyles
        );
        fillChildElement(this.exampleChild, this.exampleFormat, newValue);
        break;
      }
    }
  }

  connectedCallback(): void {
    updateClassesForAttributeChange(
      this.content,
      null,
      this.exampleFormat,
      EXAMPLE_FORMAT_VALUES,
      DEFAULT_EXAMPLE_FORMAT_VALUE,
      formatStyles
    );
    updateClassesForAttributeChange(
      this.content,
      null,
      this.exampleSize,
      EXAMPLE_SIZE_VALUES,
      DEFAULT_EXAMPLE_SIZE_VALUE,
      sizeStyles
    );
    fillChildElement(this.exampleChild, this.exampleFormat, this.exampleSize);
  }

  /*
   * PROPERTY GETTERS/SETTERS: Required
   *      For each attribute, provide a getter/setter function pair that enables the update of the component via JavaScript.
   *      Getters will only return valid values.
   *          If an attribute only accepts certain values, the getter validates the attribute before returning it.
   *          If the attribute value is invalid, then the default value should be returned.
   *      Setters normally update their associated attributes on the component.
   *          The exception to this is form-associated components, which may or may not update the attribute,
   *              depending upon the element they are replacing.
   */

  get exampleFormat(): string | null {
    const value = this.getAttribute('example-format');
    return isValueOf(EXAMPLE_FORMAT_VALUES, value)
      ? value
      : DEFAULT_EXAMPLE_FORMAT_VALUE;
  }

  set exampleFormat(value: string | null) {
    setOrRemoveAttribute(this, 'example-format', value);
  }

  get exampleSize(): string | null {
    const value = this.getAttribute('example-size');
    return isValueOf(EXAMPLE_SIZE_VALUES, value)
      ? value
      : DEFAULT_EXAMPLE_SIZE_VALUE;
  }

  set exampleSize(value: string | null) {
    setOrRemoveAttribute(this, 'example-size', value);
  }
}

export default NweaDsV1Template;
